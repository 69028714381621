import { createReducer, on } from '@ngrx/store';
import { User } from '@shared/models';
import {
	ufaEventUsersActions,
	ufaEventUsersWebsocketActions,
} from './ufa-event-users.actions';
import { upsertItem } from '@consensus/co/util-arrays';

export interface EventUsersState {
	readonly users: readonly Readonly<User>[];
}

const initialState: EventUsersState = {
	users: [],
};

export const ufaEventUsersReducer = createReducer(
	initialState,
	on(
		ufaEventUsersActions.loadEventUsersSuccess,
		(state, { users }): EventUsersState => ({
			...state,
			users,
		})
	),
	on(
		ufaEventUsersWebsocketActions.setEventUser,
		(state, { user }): EventUsersState => ({
			...state,
			users: upsertItem((x: User) => x.id === user.id, state.users, user),
		})
	)
);
