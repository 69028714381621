import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { CUBIC_IN_OUT_EASING } from './constants';

export const sliderAnimation = trigger('slider', [
	state(
		'left',
		style({
			transform: 'translateX(-100%)',
			opacity: 0.5,
			position: 'absolute',
			display: 'none',
		})
	),
	state(
		'show',
		style({
			transform: 'translateX(0)',
			opacity: 1,
			position: '*',
			display: '*',
		})
	),
	state(
		'right',
		style({
			transform: 'translateX(100%)',
			opacity: 0.5,
			position: 'absolute',
			display: 'none',
		})
	),
	transition('left => show, right => show', [
		style({ display: '*' }),
		animate(`800ms ${CUBIC_IN_OUT_EASING}`),
	]),
	transition('show => *', [animate(`800ms ${CUBIC_IN_OUT_EASING}`)]),
]);
