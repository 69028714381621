<div class="file has-name">
	<label *ngIf="!!label" class="label">
		{{ label }}
	</label>
	<div class="wrapper">
		<label class="file-wrapper">
			<input
				#uploadInput
				class="file-input"
				type="file"
				[accept]="type"
				(change)="uploadFile($event)"
				[disabled]="disabled"
			/>

			<span
				class="file-cta"
				(coFileDrop)="uploadFile($event)"
				[fileDropDisable]="disabled"
				[style.color]="
					disabled ? 'hsl(0deg, 0%, 78%)' : 'hsl(0deg, 0%, 29%)'
				"
			>
				<span class="file-icon">
					<i class="fas fa-upload"></i>
				</span>
				<span class="file-label"> Choose a file… </span>
			</span>

			<span class="file-name" *ngIf="fileUrl && fileName && !loading">
				{{ fileName }}
			</span>

			<span class="file-name" *ngIf="loading"> Loading... </span>

			<span class="icon" *ngIf="showTooltip">
				<i
					class="fad fa-info-circle"
					[matTooltip]="
						tooltipText ||
						'The uploaded file may not show immediately, with a delay up to 2 hours, due to caching.'
					"
				></i>
			</span>

			<span
				class="uploading"
				*ngIf="_uploadProgress !== null"
				[style.width]="_uploadProgress + '%'"
			></span>
		</label>

		<i
			class="remove far fa-trash-alt"
			*ngIf="!noDelete && delete.observed && fileUrl"
			(click)="onDelete()"
		></i>
	</div>
</div>

<button
	mat-flat-button
	color="primary"
	class="upload-button"
	*ngIf="!this.oldUrl && this.file"
	(click)="uploadClick()"
	[disabled]="disabled"
>
	<i class="fas fa-upload"></i>
	Upload
</button>

<div class="preview-container" *ngIf="fileUrl" [ngSwitch]="fileType">
	<ng-container *ngSwitchCase="fileTypesEnum.Image">
		<img
			class="image-preview"
			(click)="showBig = true"
			[src]="fileUrl | coBypassSecurityTrustResourceUrl"
		/>

		<co-modal
			(close)="showBig = false"
			*ngIf="fileType === fileTypesEnum.Image && showBig"
			simple="true"
		>
			<img
				class="large-image"
				[src]="fileUrl | coBypassSecurityTrustResourceUrl"
			/>
		</co-modal>
	</ng-container>

	<ng-container *ngSwitchCase="fileTypesEnum.Pdf">
		<button
			mat-flat-button
			color="primary"
			class="pdf"
			(click)="showBig = true"
		>
			<i class="fas fa-file-pdf is-white is-large"></i>
			Show PDF file
		</button>

		<co-modal *ngIf="showBig" (close)="showBig = false" simple="true">
			<co-encoded-pdf [src]="fileUrl" [allowSharing]="true" />
		</co-modal>
	</ng-container>

	<ng-container *ngSwitchCase="fileTypesEnum.Video">
		<div class="video-button" *ngIf="!showBig" (click)="showBig = true">
			<i class="fas fa-play"></i>
		</div>

		<video
			*ngIf="showBig"
			playsinline
			controls
			[src]="fileUrl | coBypassSecurityTrustResourceUrl"
			preload="none"
			autoplay
		></video>

		<co-file-upload
			*ngIf="uploadThumb.observed"
			type="image/*"
			label="Video Thumbnail"
			[src]="oldUrl + '?thumbnail=1'"
			[uploadProgress]="uploadProgressThumb"
			(upload)="uploadThumb.emit($event.file)"
			(delete)="deleteThumb.emit($event)"
			[noDelete]="!deleteThumb.observed"
			[showTooltip]="true"
		/>
	</ng-container>

	<ng-container *ngSwitchCase="fileTypesEnum.Audio">
		<audio
			controls
			[src]="fileUrl | coBypassSecurityTrustResourceUrl"
		></audio>
	</ng-container>
</div>
