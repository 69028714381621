import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventUsersState } from './ufa-event-users.reducer';
import { keyBy } from 'lodash-es';
import { sortAlphAsc } from '@lib/helpers';
import { Role } from '@shared/models';

export const usersKey = 'users';

export interface GlobalEventUsersState {
	[usersKey]: EventUsersState;
}

const selectEventUsersState = createSelector(
	createFeatureSelector<GlobalEventUsersState>('event'),
	state => state.users
);

export const selectAllEventUsers = createSelector(
	selectEventUsersState,
	state => state.users
);

export const selectAllEventUsersLookup = createSelector(
	selectAllEventUsers,
	x => keyBy(x, x => x.id)
);

export const selectAllPresentableEventUsers = createSelector(
	selectAllEventUsers,
	users =>
		users
			.filter(u => !u.observer)
			.filter(u => !u.disabled)
			.sort(sortAlphAsc(u => u.firstName?.toLocaleLowerCase() ?? ''))
);

export const selectEventUsers = createSelector(selectAllEventUsers, users =>
	users.filter(u => u.role == Role.User)
);

export const selectEventUsersLookup = createSelector(selectEventUsers, x =>
	keyBy(x, x => x.id)
);

export const selectEventUserAdmins = createSelector(
	selectAllEventUsers,
	users => users.filter(u => u.role == Role.UserAdmin)
);

export const selectEventUserAdminsLookup = createSelector(
	selectEventUserAdmins,
	x => keyBy(x, x => x.id)
);
