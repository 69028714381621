<div *ngIf="!fluidHeight" class="padding"></div>

<p class="error-message" *ngIf="videoFailed">Failed to load video content</p>

<ng-container *ngIf="!videoFailed">
	<video
		[class.abs]="!fluidHeight"
		*ngIf="!loadingVideo && videoUrl"
		playsinline
		[controls]="
			!proxy &&
			!loop &&
			(!enableCapacitorHotfix || (justManuallyNativeClicked | ngrxPush))
		"
		[src]="videoUrl | coBypassSecurityTrustResourceUrl"
		autoplay
		(loadedmetadata)="loaded($event)"
		(ended)="onEnd()"
		(play)="onPlay()"
		(playing)="onStart()"
		#video
		[muted]="muted"
		(waiting)="onBuffer()"
		(pause)="onPause()"
		[class.paused]="paused"
		[loop]="loop"
		[controlsList]="allowDownload ? '' : 'nodownload'"
		(contextmenu)="onContextMenu()"
		(click)="videoElementClicked()"
	></video>

	<ng-container *ngIf="!autoplay">
		<div class="poster" *ngIf="!videoUrl && !loadingImage">
			<img
				*ngIf="thumbnailUrl"
				[src]="thumbnailUrl | coBypassSecurityTrustResourceUrl"
				alt=""
			/>
		</div>

		<div
			class="video-button"
			*ngIf="
				!loadingVideo &&
				(videoElement?.nativeElement.paused || !videoUrl)
			"
			(click)="videoClick($event)"
		>
			<i
				*ngIf="!enableCapacitorHotfix || !hasPlayed"
				class="fas fa-play"
				[style]="{ fontSize: iconSize }"
			></i>
		</div>
	</ng-container>

	<ng-container *ngIf="completed.observed">
		<div
			class="cheater message"
			*ngIf="
				enableCompletenessMessages && !watched && cheated && videoUrl
			"
			@fade-in
		>
			<i class="fas fa-exclamation-triangle"></i>
			You missed parts of this video, go back and watch to mark this as
			completed
		</div>

		<div
			class="watched message"
			*ngIf="enableCompletenessMessages && watched"
			@fade-in
		>
			<i class="fas fa-check-circle"></i>
			You have watched this video!
		</div>

		<button
			class="button admin-button"
			*ngIf="isDevelopmentMode || admin || allowSkipping"
			(click)="completed.emit()"
		>
			Skip
		</button>
	</ng-container>

	<co-spinner
		*ngIf="(loadingImage && !videoUrl) || loadingVideo || buffering"
		layout="centered"
	/>
</ng-container>
