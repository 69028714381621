import { novofy } from '@lib/helpers';
import {
	AbstractControlOptions,
	AsyncValidatorFn,
	UntypedFormControl,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import {
	BehaviorSubject,
	Observable,
	Subject,
	asyncScheduler,
	throttleTime,
} from 'rxjs';
import { parseDate } from '@lib/helpers';
import { DistributionUpdateModel, getDefaultDist } from '@store/distributions';

export enum FormNodeEvent {
	Focus = 'focus',
}

export enum InputTypes {
	Text = 'text',
	Url = 'url',
	Number = 'number',
	Password = 'password',
	Bool = 'boolean',
	Date = 'text-date',
	DateTime = 'datetime-local',
	Time = 'time',
	Color = 'color',
	Email = 'email',
	Phone = 'tel',
	LongText = 'textarea',
	HTML = 'html',
	Select = 'select',
	SelectMany = 'multipleSelect',
	Search = 'search',
	File = 'file',
	Distribution = 'distribution',
}

const dateTypes = [InputTypes.Date, InputTypes.DateTime, InputTypes.Time];
const selectTypes = [InputTypes.Select, InputTypes.SelectMany];
const textTypes = [
	InputTypes.Text,
	InputTypes.Url,
	InputTypes.Password,
	InputTypes.Color,
	InputTypes.Email,
	InputTypes.Phone,
	InputTypes.LongText,
	InputTypes.HTML,
	InputTypes.Search,
];

export class FormNode<TInput = any> extends UntypedFormControl {
	actions$ = new Subject<FormNodeEvent>();

	readonly valueChanges: Observable<TInput>;

	protected _value$: BehaviorSubject<TInput>;
	value$: Observable<TInput>;
	throttledValue$: Observable<TInput>;

	type: InputTypes = InputTypes.Text;
	label: string;
	autocomplete: string;
	tooltip: string;
	readonly: boolean;
	showCopy: boolean;
	autoFocus: boolean;
	hideDisabled = true;
	forceNullPatch = false;
	hint: string;

	static clone<TClone>(node: FormNode<TClone>): FormNode<TClone> {
		const clone = new FormNode(node.value, node.validator, node.asyncValidator);
		return clone.from(node) as FormNode<TClone>;
	}

	static id() {
		return new FormNode<string>(null)
			.withType(InputTypes.Text)
			.withLabel('Id')
			.withCopy()
			.lock();
	}

	static text(defaultValue = '') {
		return new FormNode<string>(defaultValue).withType(InputTypes.Text);
	}

	static url(defaultValue = '') {
		return new FormNode<string>(defaultValue).withType(InputTypes.Url);
	}

	static number(defaultValue: number = null) {
		return new FormNode<number>(defaultValue).withType(InputTypes.Number);
	}

	static password(defaultValue = '') {
		return new FormNode<string>(defaultValue).withType(InputTypes.Password);
	}

	static bool(defaultValue = false) {
		return new FormNode<boolean>(defaultValue).withType(InputTypes.Bool);
	}

	static date(defaultValue: Date = null) {
		return new FormNode<Date>(defaultValue).withType(InputTypes.Date);
	}

	static datetime(defaultValue: Date = null) {
		return new FormNode<Date>(defaultValue).withType(InputTypes.DateTime);
	}

	static time(defaultValue: Date = null) {
		return new FormNode<Date>(defaultValue).withType(InputTypes.Time);
	}

	static color(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.Color);
	}

	static email(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.Email);
	}

	static phone(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.Phone);
	}

	static longText(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.LongText);
	}

	static html(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.HTML);
	}

	static search(defaultValue: string = null) {
		return new FormNode<string>(defaultValue).withType(InputTypes.Search);
	}

	static distribution() {
		return new FormNode<DistributionUpdateModel>(getDefaultDist()).withType(
			InputTypes.Distribution
		);
	}

	static file(defaultValue: File = null) {
		return new FormNode<File>(defaultValue).withType(InputTypes.File);
	}

	static select(defaultValue: any = null) {
		return new FormSelectNode(defaultValue).withType(InputTypes.Select);
	}

	static selectMany(defaultValue: any[] = []) {
		const node = new FormSelectNode<any[], any>(defaultValue).withType(
			InputTypes.SelectMany
		);
		node.multiple = true;
		return node;
	}

	constructor(
		formState?: TInput,
		validatorOrOpts?:
			| ValidatorFn
			| ValidatorFn[]
			| AbstractControlOptions
			| null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
	) {
		super(formState, validatorOrOpts, asyncValidator);

		this._value$ = new BehaviorSubject<TInput>(this.value);
		this.valueChanges.subscribe(this._value$);

		this.value$ = this._value$.asObservable();
		this.throttledValue$ = this._value$.pipe(
			throttleTime(500, asyncScheduler, { trailing: true })
		);
	}

	setValue(
		value: TInput,
		options?: {
			onlySelf?: boolean;
			emitEvent?: boolean;
			emitModelToViewChange?: boolean;
			emitViewToModelChange?: boolean;
		}
	) {
		super.setValue(this.preprocessValue(value), options);
	}

	patchValue(
		value: TInput,
		options?: {
			onlySelf?: boolean;
			emitEvent?: boolean;
			emitModelToViewChange?: boolean;
			emitViewToModelChange?: boolean;
		}
	) {
		super.patchValue(this.preprocessValue(value), options);
	}

	// This method correlates directly to the backend class `AutomapperConfigExtensions`
	getValue(): TInput {
		if (this.forceNullPatch) {
			if (!this.value) {
				if (dateTypes.includes(this.type)) {
					return new Date(Date.UTC(1900, 0, 1)) as unknown as TInput;
				}

				if (textTypes.includes(this.type)) {
					return 'null' as unknown as TInput;
				}

				if (this.type == InputTypes.SelectMany) {
					return [] as unknown as TInput;
				}

				if (this.type == InputTypes.Select) {
					return 'null' as unknown as TInput;
				}
			} else if (
				(textTypes.includes(this.type) || selectTypes.includes(this.type)) &&
				this.value == 'null'
			) {
				return '\\null' as unknown as TInput;
			}
		}

		return this.value;
	}

	preprocessValue(value: any) {
		if (this.type === InputTypes.Distribution) {
			const { name, id, ...val } = value;
			return val;
		}

		if (dateTypes.includes(this.type) && !(value instanceof Date)) {
			return parseDate(value, true, true);
		}

		return value;
	}

	from(node: FormNode<TInput>): FormNode {
		this.type = node.type;
		this.label = node.label;
		this.autocomplete = node.autocomplete;
		this.tooltip = node.tooltip;
		this.readonly = node.readonly;
		this.showCopy = node.showCopy;
		this.hideDisabled = node.hideDisabled;
		this.autoFocus = node.autoFocus;
		this.hint = node.hint;
		return this;
	}

	required() {
		this.withValidators(Validators.required);
		return this;
	}

	withLabel(label: string) {
		this.label = label;
		return this;
	}

	autocompleteAs(autocomplete: string) {
		this.autocomplete = autocomplete;
		return this;
	}

	withValidators(...validators: ValidatorFn[]) {
		if (this.validator) {
			this.setValidators([this.validator, ...validators]);
		} else {
			this.setValidators(validators);
		}
		this.updateValueAndValidity();
		return this;
	}

	withType(type: InputTypes) {
		this.type = type;
		return this;
	}

	withTooltip(tooltip: string) {
		this.tooltip = tooltip;
		return this;
	}

	showDisabled() {
		this.hideDisabled = false;
		return this;
	}

	lock() {
		this.readonly = true;
		return this;
	}

	withCopy() {
		this.showCopy = true;
		return this;
	}

	withFocus() {
		this.autoFocus = true;
		return this;
	}

	forceNull() {
		this.forceNullPatch = true;
		return this;
	}

	focus() {
		setTimeout(() => this.actions$.next(FormNodeEvent.Focus), 0);
	}

	toggle() {
		if (this.type !== InputTypes.Bool) {
			console.error('You cannot toggle a non boolean value');
			return;
		}

		this.setValue(!this.value as unknown as TInput);
	}

	novofy(isSema: boolean) {
		this.label = novofy(isSema, this.label);
	}
}

export class FormSelectNode<TInput, TItem> extends FormNode<TInput> {
	bindLabel: string | ((item: TItem) => string);
	bindOption: string | ((item: TItem) => string);
	bindValue: string;
	items: TItem[];
	items$: Observable<TItem[]>;
	multiple = false;
	groupProp: string | ((x: TItem) => string);
	selectGroups = false;
	searchFn: (query: string, ses: TItem) => boolean;
	clearable = true;
	hideWhenEmpty = false;

	static clone<TIn, TIt>(
		node: FormSelectNode<TIn, TIt>
	): FormSelectNode<TIn, TIt> {
		const clone = new FormSelectNode(
			node.value,
			node.validator,
			node.asyncValidator
		);
		return clone.from(node) as FormSelectNode<TIn, TIt>;
	}

	from(node: FormSelectNode<TInput, TItem>): FormNode {
		super.from(node);
		this.bindLabel = node.bindLabel;
		this.bindOption = node.bindOption;
		this.bindValue = node.bindValue;
		this.items = node.items;
		this.items$ = node.items$;
		this.multiple = node.multiple;
		this.groupProp = node.groupProp;
		this.selectGroups = node.selectGroups;
		this.searchFn = node.searchFn;
		this.clearable = node.clearable;
		this.hideWhenEmpty = node.hideWhenEmpty;
		return this;
	}

	withOptions<TOption>(
		options: Observable<TOption[]> | TOption[]
	): FormSelectNode<TInput, TOption> {
		const node = this as unknown as FormSelectNode<TInput, TOption>;
		if (options instanceof Observable) {
			node.items$ = options;
		} else {
			node.items = options;
		}
		return node;
	}

	withBinds(
		value: string = null,
		label: string | ((item: TItem) => string) = null,
		optionBinding: string | ((item: TItem) => string) = null
	) {
		this.bindValue = value;
		this.bindLabel = label;
		this.bindOption = optionBinding;
		return this;
	}

	groupBy(prop: string | ((x: TItem) => string), selectGroups = false) {
		this.groupProp = prop;
		this.selectGroups = selectGroups;
		return this;
	}

	withSearch(searchFn: (query: string, ses: TItem) => boolean) {
		this.searchFn = searchFn;
		return this;
	}

	noClear() {
		this.clearable = false;
		return this;
	}

	hideEmpty() {
		this.hideWhenEmpty = true;
		return this;
	}
}
