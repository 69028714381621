/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
	NavigationEnd,
	NavigationStart,
	Router,
	RouterOutlet,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { CookieService } from '@consensus/shared/shared/analytics/data-access';
import {
	pushNotificationActionHandler,
	PushNotificationRegistrationService,
} from '@consensus/connect/ufa/capacitor/data-access-push-notifications';
import { AppflowLiveUpdateService } from '@consensus/connect/ufa/capacitor/util-appflow-live-updates';
import { DeepLinkingService } from '@consensus/connect/ufa/capacitor/util-deep-linking';
import { CapacitorSafeLinksService } from '@consensus/connect/ufa/capacitor/util-safe-links';
import { PopupComponent } from '@consensus/legacy/ui-popup';
import { ThemeService } from '@core/services';
import { environmentToken } from '@environments/environment';
import { Store } from '@ngrx/store';
import { UploadProgressComponent } from '@shared/components';
import { selectEvent } from '@store/scope';
import { defaultTheme, selectTheme } from '@store/theming';
import { forOwn, isEmpty } from 'lodash-es';
import { filter } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-root',
	imports: [RouterOutlet, PopupComponent, UploadProgressComponent],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	readonly #pushNotificationRegistration = inject(
		PushNotificationRegistrationService
	);
	readonly #capacitorSafeLinks = inject(CapacitorSafeLinksService);
	readonly #appflowLiveUpdate = inject(AppflowLiveUpdateService);
	readonly #deepLinking = inject(DeepLinkingService);
	readonly #cookieService = inject(CookieService);
	readonly #themeService = inject(ThemeService);
	readonly #store = inject(Store);
	readonly #router = inject(Router);
	readonly #capturePolicy = inject(environmentToken).posthog.capturePolicy;
	readonly #document = inject(DOCUMENT);
	readonly #webUrl = inject(environmentToken).wrappedApp?.webUrl;
	#pushNotificationActionHandler = pushNotificationActionHandler();

	constructor() {
		/** Capacitor specific operations */
		if (Capacitor.isNativePlatform()) {
			if (this.#webUrl) {
				// Init listener for deep linking events, is noop in non-Capacitor build
				this.#deepLinking.initDeepLinkingListener(this.#webUrl);
			}
			this.#pushNotificationRegistration.setup();
			/** Setup the recurring update check for Appflow, is a noop in non-Capacitor build	*/
			this.#appflowLiveUpdate.setupRecurringCheck();
			/** Setup global listener for link clicks */
			this.#capacitorSafeLinks.setupCapacitorSafeLinkEventListener();
			this.#pushNotificationActionHandler.init();
		}

		this.#router.events
			.pipe(filter(x => x instanceof NavigationEnd))
			.subscribe((e: NavigationEnd) => {
				console.debug(`%cNavigated to: ${e.url}`, 'color: #999');
			});

		this.#router.events
			.pipe(filter(x => x instanceof NavigationStart))
			.subscribe((e: NavigationStart) => {
				console.debug(`%cNavigating to: ${e.url}`, 'color: #666');
			});
	}

	ngOnInit() {
		this.#store.select(selectTheme).subscribe(({ semaStyling, styles }) => {
			if (isEmpty(styles)) {
				styles = defaultTheme.styles;
			}

			const { primary, primaryText, secondary, secondaryText, ...s } = styles;

			forOwn(s, (value, name) => {
				this.#document.documentElement.style.setProperty(
					`--theme__${name}`,
					value
				);
			});

			this.#themeService.updateTheme(styles);

			this.#document.body.classList.toggle('novo-theming', semaStyling);
			this.#document.documentElement.style.setProperty(
				`--theme__footer-height`,
				semaStyling ? '40px' : '0px'
			);
			this.#document.documentElement.style.setProperty(
				`--theme__font-stack`,
				'Roboto, "Helvetica Neue", sans-serif'
			);
		});

		this.#store.select(selectEvent).subscribe(event => {
			this.#document.documentElement.style.setProperty(
				`--theme__footer-height`,
				event?.showFooter ? '40px' : '0px'
			);
		});

		if (this.#capturePolicy === 'RequireInformedConsent') {
			this.#cookieService.consentCheck();
		}
	}
}
