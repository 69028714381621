/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { Subscription } from 'rxjs';
import { CoSnackService } from '@consensus/co/ui-snackbars';
import { MediaProxy } from '@shared/models';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';
import { NgIf } from '@angular/common';
import Timer = NodeJS.Timer;

@Component({
	selector: 'co-encoded-audio',
	templateUrl: './encoded-audio.component.html',
	styleUrls: ['./encoded-audio.component.scss'],
	// For now we will not enable OnPush, but instead just markForCheck whenever appropriate
	// changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, BypassSecurityTrustResourceUrlPipe],
})
export class EncodedAudioComponent implements OnDestroy {
	readonly #changeDetectionRef = inject(ChangeDetectorRef);
	readonly #snacks = inject(CoSnackService);
	readonly #fileService = inject(FileCacheService);
	updateInterval = 60;

	@ViewChild('audio') audioElement: ElementRef<HTMLAudioElement>;

	audioUrl: string;
	failed = false;
	loading = false;
	#reload = new Subscription();
	#oldProgress = 0;
	#oldPercentage = 0;

	@Input() alt: string;
	@Input() allowSkipping = false;
	@Input() allowDownload = false;
	@Input() autoplay = false;
	@Input() loop = false;
	@Input() muted = false;
	@HostBinding('class.hide') @Input() hidden = false;

	#src = '';
	@Input()
	set src(src: string) {
		if (src === this.#src) {
			return;
		}
		this.#src = src;
		this.loadAudio();
		this.#changeDetectionRef.markForCheck();
	}

	@Input()
	set attachment(id: string) {
		this.src = `attachment/${id}`;
	}

	@Input()
	set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}

	@Input()
	set surveySection(id: string) {
		this.src = `survey-section/${id}`;
	}

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	_proxy: MediaProxy;
	@Input() set proxy(proxy: MediaProxy) {
		this._proxy = proxy;
		if (this.audioElement?.nativeElement) {
			proxy.register(this.audioElement.nativeElement);
		}
	}

	get proxy() {
		return this._proxy;
	}

	@Output() completionUpdate = new EventEmitter<number>();
	@Output() completed = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() ended = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() start = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() pause = new EventEmitter<void>();

	@Input() watchedPercentage = 0;
	@Input() watched = false;

	cheated = false;
	#fullDuration: number;
	#duration: number;
	#playingStart: Date;
	#played = 0;
	#lastUpdate = 0;
	paused = true;
	buffering = false;
	#updateTimer: Timer;

	private async loadAudio() {
		this.loading = true;
		this.failed = false;
		this.audioUrl = null;

		this.#changeDetectionRef.markForCheck();

		try {
			this.audioUrl = await this.#fileService.getFileUrl(this.#src);

			if (this.proxy) {
				setTimeout(() => {
					if (this.proxy.media != this.audioElement?.nativeElement) {
						this.proxy.register(this.audioElement?.nativeElement);
					}
				});
			}
		} catch (e) {
			console.error(e);
			this.failed = true;
		} finally {
			this.loading = false;

			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = this.#fileService.onReload(this.#src, () => {
				this.audioUrl = null;
				this.#oldProgress = this.audioElement?.nativeElement?.currentTime ?? 0;
				this.loadAudio();
			});

			this.#changeDetectionRef.markForCheck();
		}
	}

	loaded(e) {
		this.#duration = e.target.duration * 0.8;
		this.#fullDuration = e.target.duration;
		this.#playingStart = null;
		this.paused = true;
		this.buffering = false;
		this.pause.emit();

		if (this.#oldPercentage) {
			this.#oldProgress = (this.#oldPercentage / 100) * this.#duration;
			this.#played = this.#oldProgress;
			this.#lastUpdate = this.#played;
			this.#oldPercentage = null;
		}

		if (!this.audioElement?.nativeElement) {
			return;
		}

		this.audioElement.nativeElement.currentTime = this.#oldProgress;

		if (this.autoplay) {
			this.audioElement.nativeElement.play();
		}

		this.#changeDetectionRef.markForCheck();
	}

	onStart() {
		this.#playingStart = new Date();
		this.paused = false;
		this.buffering = false;
		this.start.emit();
		this.#updateTimer = setInterval(
			() => this.updatePercentage(),
			(this.updateInterval + 2) * 1000
		);

		this.#changeDetectionRef.markForCheck();
	}

	onStop() {
		if (!this.#playingStart) {
			return;
		}
		this.#played +=
			(new Date().getTime() - this.#playingStart.getTime()) / 1000 + 1;
		this.#playingStart = null;
		clearInterval(this.#updateTimer);
		this.updatePercentage();

		this.#changeDetectionRef.markForCheck();
	}

	onPause() {
		this.onStop();
		this.paused = true;
		this.pause.emit();

		if (this.#duration && this.#played > this.#duration && !this.watched) {
			this.completed.emit();
		}

		this.#changeDetectionRef.markForCheck();
	}

	onEnd() {
		this.audioElement.nativeElement.pause();

		this.onStop();

		if (this.completed.observed) {
			if (
				this.allowSkipping ||
				(this.#duration && this.#played > this.#duration)
			) {
				if (!this.watched) {
					this.completed.emit();
				}
			} else if (!this.watched) {
				this.cheated = true;
				this.#snacks.error(
					'You need to listen to the entire podcast',
					'No Skipping!'
				);
			}
		}

		this.ended.emit();

		this.#changeDetectionRef.markForCheck();
	}

	onBuffer() {
		this.buffering = true;
		this.onStop();

		this.#changeDetectionRef.markForCheck();
	}

	updatePercentage() {
		if (!this.completionUpdate.observed) {
			return;
		}
		if (!this.#playingStart) {
			return;
		}

		const currentTime =
			this.#played +
			(new Date().getTime() - this.#playingStart.getTime()) / 1000 +
			1;
		const timeElapsed = currentTime - this.#lastUpdate;

		if (timeElapsed >= this.updateInterval) {
			const percentage = (currentTime * 100) / this.#fullDuration;
			this.completionUpdate.emit(percentage);
			this.#lastUpdate = currentTime;
		}

		this.#changeDetectionRef.markForCheck();
	}

	ngOnDestroy() {
		clearInterval(this.#updateTimer);
		this.proxy?.deregister(this.audioElement?.nativeElement);
	}
}
