import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarModule,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export interface PlainSnackData {
	readonly icon: string | null;
	readonly isHtml: boolean;
	readonly message: string;
	readonly onClick: (() => void) | null;
	readonly title: string | null;
	readonly type: 'success' | 'info' | 'warning' | 'plain';
}

@Component({
	standalone: true,
	selector: 'co-plain-snack',
	imports: [NgIf, MatSnackBarModule, MatButtonModule, FontAwesomeModule],
	templateUrl: './co-plain-snack.component.html',
	styleUrls: ['./co-plain-snack.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoPlainSnackComponent {
	readonly #snackbar: MatSnackBarRef<CoPlainSnackComponent> =
		inject(MatSnackBarRef);
	readonly data: PlainSnackData = inject(MAT_SNACK_BAR_DATA);
	readonly typeIcon = faCircle;

	onMessageClick() {
		if (this.data.onClick) {
			this.data.onClick();
			this.onMessageDismiss();
		}
	}

	onMessageDismiss() {
		this.#snackbar.dismiss();
	}
}
