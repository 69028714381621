import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Dashboard } from '@consensus/connect/shared/dashboard/domain';
import {
	completeDashboardItemActivity,
	getDashboards,
} from './ufa-dashboard.store';
import { dispatchAsync } from '@lib/redux';

@Injectable({
	providedIn: 'root',
})
export class UfaDashboardActivityDashboardService {
	#dashboards$ = new BehaviorSubject<Dashboard[]>([]);
	readonly #store = inject(Store);

	get activityDashboards() {
		return this.#dashboards$.value.filter(x => x.visualTrackingEnabled);
	}

	get activityDashboardItems() {
		return this.activityDashboards
			.map(x => x.dashboardItems)
			.reduce((acc, value) => acc.concat(value), [])
			.filter(x => x.link);
	}

	constructor() {
		this.#store.select(getDashboards.selector).subscribe(this.#dashboards$);
	}

	async completeActivityAsync(id: string) {
		if (!this.activityDashboards) {
			return;
		}
		const dashboardItems = this.getDashboardItemsLinkedToActivity(id);
		if (!dashboardItems) {
			return;
		}
		for (const item of dashboardItems.filter(x => !x.isCompleted)) {
			await dispatchAsync(completeDashboardItemActivity, item.id);
		}
	}

	private getDashboardItemsLinkedToActivity(activityId: string) {
		return this.activityDashboardItems.filter(x => x.link.includes(activityId));
	}
}
