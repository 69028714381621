import { inject, Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { posthogToken } from '../posthog/posthog.token';

@Injectable()
export class Angulartics2PostHog {
	readonly #posthog = inject(posthogToken);
	readonly #angulartics2 = inject(Angulartics2);

	constructor() {
		this.#angulartics2.setUsername.subscribe((x: string) =>
			this.setUsername(x)
		);
		this.#angulartics2.setUserProperties.subscribe(x =>
			this.setUserProperties(x)
		);
		this.#angulartics2.setUserPropertiesOnce.subscribe(x =>
			this.setUserPropertiesOnce(x)
		);
		this.#angulartics2.setSuperProperties.subscribe(x =>
			this.setSuperProperties(x)
		);
		this.#angulartics2.setSuperPropertiesOnce.subscribe(x =>
			this.setSuperPropertiesOnce(x)
		);
		this.#angulartics2.setAlias.subscribe(x => this.setAlias(x));
	}

	startTracking(): void {
		this.#angulartics2.pageTrack
			.pipe(this.#angulartics2.filterDeveloperMode())
			.subscribe(x => this.pageTrack(x.path));
		this.#angulartics2.eventTrack
			.pipe(this.#angulartics2.filterDeveloperMode())
			.subscribe(x => this.eventTrack(x.action, x.properties));
	}

	private pageTrack(path: string) {
		try {
			this.#posthog.capture('$pageview', { page: path });
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private eventTrack(action: string, properties: any) {
		try {
			this.#posthog.capture(action, properties);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setUsername(userId: string) {
		try {
			this.#posthog.identify(userId);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setUserProperties(properties: any) {
		try {
			this.#posthog.people.set(properties);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setUserPropertiesOnce(properties: any) {
		try {
			this.#posthog.people.set_once(properties);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setSuperProperties(properties: any) {
		try {
			this.#posthog.register(properties);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setSuperPropertiesOnce(properties: any) {
		try {
			this.#posthog.register_once(properties);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}

	private setAlias(alias: any) {
		try {
			this.#posthog.alias(alias);
		} catch (e) {
			if (!(e instanceof ReferenceError)) {
				throw e;
			}
		}
	}
}
