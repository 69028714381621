import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarModule,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
export interface ErrorSnackData<TPayload> {
	readonly isHtml: boolean;
	readonly message: string;
	readonly payload: TPayload | null;
	readonly title: string | null;
	readonly type: 'error';
}

@Component({
	standalone: true,
	selector: 'co-error-snack',
	imports: [NgIf, MatSnackBarModule, MatButtonModule, FontAwesomeModule],
	templateUrl: './co-error-snack.component.html',
	styleUrls: ['./co-error-snack.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoErrorSnackComponent<TPayload> {
	readonly #clipboard = inject(Clipboard);
	readonly #snackbar: MatSnackBarRef<CoErrorSnackComponent<TPayload>> =
		inject(MatSnackBarRef);
	readonly data: ErrorSnackData<TPayload> = inject(MAT_SNACK_BAR_DATA);
	readonly typeIcon = faCircle;

	onErrorCopy() {
		this.#clipboard.copy(
			this.data.payload ? JSON.stringify(this.data.payload) : this.data.message
		);
		this.onErrorDismiss();
	}

	onErrorDismiss() {
		this.#snackbar.dismiss();
	}
}
