export interface UserInformation {
	id: string;
	firstName: string | null;
	lastName: string | null;
	username: string;
	email?: string;
}

export interface User extends UserInformation {
	role?: Role;
	observer?: boolean;
	disabled?: boolean;
}

export interface AdminUser extends User {
	phoneNumber?: string;
	email?: string;
	pin?: string;
	singleSignOn?: boolean;
	userAdmin?: boolean;
	observer?: boolean;
	contentOwner?: boolean;
	isOnline?: boolean;
	accessFailedCount?: number;
	twoFactorEnabled?: boolean;
	claims?: UserClaim[];
}

export interface SearchUser {
	id: string;
	name: string;
	userName: string;
	email: string;
	isOnline?: boolean;
	selected?: boolean;
}

export interface UserClaim {
	id: string;
	userId: string;
	type: string;
	value: string;
}

export interface AuthedUser extends AdminUser {
	events?: string[];
	groups?: string[];
	anonymous: boolean;
	permissions: Permission[];
	clientId: string;
}

export interface SimpleGroup {
	id: string;
	name: string;
}

export enum Role {
	User = 'User',
	UserAdmin = 'UserAdmin',
	Admin = 'Admin',
	SuperAdmin = 'SuperAdmin',
	Moderator = 'Moderator',
}

export enum Permission {
	SocialFeedCreate = 'SocialFeedCreate',
	SocialFeedDelete = 'SocialFeedDelete',
	SocialFeedModify = 'SocialFeedModify',
	NotificationCreate = 'NotificationCreate',
	NotificationDelete = 'NotificationDelete',
}
