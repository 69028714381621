import { DOCUMENT } from '@angular/common';
import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { fromEvent, Subscription, asyncScheduler, throttleTime } from 'rxjs';
import { FormNode, FormNodeEvent } from '@lib/forms';
import { ControlContainer } from '@angular/forms';

@Directive({
	standalone: true,
	selector: '[coNumberEditable]',
})
export class NumberEditableDirective implements OnInit, OnDestroy {
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	readonly #document = inject(DOCUMENT);
	readonly #controlContainer = inject(ControlContainer, {
		optional: true,
		host: true,
		skipSelf: true,
	});

	subs = new Subscription();

	@Input() controlName: string;
	@Input() control: FormNode<number>;

	@Input() allowFloat = false;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<void>();
	@Output() exit = new EventEmitter<void>();

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (event.code === 'Enter') {
			event.preventDefault();
			this.submit.emit();
			return;
		}

		if (event.code === 'Escape') {
			event.stopPropagation();
			this.exit.emit();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const text = event.clipboardData.getData('text');
		this.#document.execCommand('insertText', false, text);
		this.setValue(this.#element.nativeElement.innerText);
	}

	ngOnInit() {
		if (!this.control && !this.controlName) {
			return;
		}

		this.validateControl();
		if (!this.control) {
			return;
		}

		this.#element.nativeElement.contentEditable = 'true';

		this.subs.add(this.control.value$.subscribe(x => this.setValue(x)));

		this.subs.add(
			this.control.actions$.subscribe(x => {
				if (x === FormNodeEvent.Focus) {
					setTimeout(() => this.#element.nativeElement.focus());
				}
			})
		);

		this.subs.add(
			fromEvent(this.#element.nativeElement, 'input')
				.pipe(throttleTime(500, asyncScheduler, { trailing: true }))
				.subscribe(() =>
					this.control.setValue(Number(this.#element.nativeElement.innerText))
				)
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	private validateControl() {
		if (this.control instanceof FormNode) {
			return;
		}

		if (this.controlName) {
			const control = this.#controlContainer?.control?.get(this.controlName);
			if (control && control instanceof FormNode) {
				this.control = control;
				return;
			}
		}

		console.error('contenteditable was not given proper FormNode');
	}

	private setValue(val: string | number) {
		if (!val && !this.#element.nativeElement.innerText) {
			return;
		}

		const value = Number(val);

		if (Number.isNaN(value)) {
			this.#element.nativeElement.innerText = '';
			return;
		}

		const text = this.allowFloat ? value.toString() : value.toFixed(0);

		if (text == this.#element.nativeElement.innerText) {
			return;
		}

		this.#element.nativeElement.innerText = text;
	}
}
