import { inject, isDevMode } from '@angular/core';
import {
	ApiAction,
	IErrorAction,
	IStartAction,
	ISuccessAction,
} from './action';
import { BaseState, LoadingState } from '@shared/models';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import {
	Observable,
	of,
	OperatorFunction,
	catchError,
	filter,
	map,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';
import { ofType } from '@ngrx/effects';
import { parseObject } from '@lib/helpers';
import { parseDate } from '@lib/helpers';
import { BaseSelector, Selector } from './selector';

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export type ApiEffect<TResponse, TPayload> = (
	actions$: Observable<Action>
) => Observable<ISuccessAction<TResponse, TPayload> | IErrorAction<TPayload>>;

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiEffects<TState extends BaseState> {
	readonly #$mainStore: Store<TState> = inject(Store);
	readonly #isProductionMode = !isDevMode();

	selector: MemoizedSelector<object, TState>;

	constructor(
		mainSelector: MemoizedSelector<object, TState> | BaseSelector<TState, any>
	) {
		this.selector =
			mainSelector instanceof Selector ? mainSelector.selector : mainSelector;
	}

	generateApiEffect<TPayload, TResponse>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		serviceCall: (input: IStartAction<TPayload>) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse, T2>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		pipe1: OperatorFunction<IStartAction<TPayload>, T2>,
		serviceCall: (input: T2) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse, T2, T3>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		pipe1: OperatorFunction<IStartAction<TPayload>, T2>,
		pipe2: OperatorFunction<T2, T3>,
		serviceCall: (input: T3) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse, T2, T3, T4>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		pipe1: OperatorFunction<IStartAction<TPayload>, T2>,
		pipe2: OperatorFunction<T2, T3>,
		pipe3: OperatorFunction<T3, T4>,
		serviceCall: (input: T4) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse, T2, T3, T4, T5>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		pipe1: OperatorFunction<IStartAction<TPayload>, T2>,
		pipe2: OperatorFunction<T2, T3>,
		pipe3: OperatorFunction<T3, T4>,
		pipe4: OperatorFunction<T4, T5>,
		serviceCall: (input: T5) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse, T2, T3, T4, T5, T6>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		pipe1: OperatorFunction<IStartAction<TPayload>, T2>,
		pipe2: OperatorFunction<T2, T3>,
		pipe3: OperatorFunction<T3, T4>,
		pipe4: OperatorFunction<T4, T5>,
		pipe5: OperatorFunction<T5, T6>,
		serviceCall: (input: T6) => Observable<TResponse>
	): ApiEffect<TPayload, TResponse>;
	generateApiEffect<TPayload, TResponse>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		...args: ((x: any) => any)[]
	) {
		return (
			actions$: Observable<Action>
		): Observable<
			ISuccessAction<TResponse, TPayload> | IErrorAction<TPayload>
		> => {
			if (!actions$) {
				console.error(
					'actions$ not set in effect. Remember to call the result of generateApiEffect with actions$.'
				);
				return of(
					apiAction.fail(
						`actions$ not passed to effect factory`
					) as IErrorAction<TPayload>
				);
			}
			if (args.length < 1) {
				throw Error('a service call is required');
			}
			const serviceCall: (payload: TPayload) => Observable<TResponse> =
				args[args.length - 1];

			const pipeList = [];
			for (let i = 0; i < args.length - 1; i++) {
				pipeList.push(args[i]);
			}

			let tempAction: IStartAction<TPayload>;
			let start: Date;

			const extraPipes = [];

			if (this.#$mainStore && this.selector && apiAction.settings.initialLoad) {
				extraPipes.push(withLatestFrom(this.#$mainStore.select(this.selector)));
				extraPipes.push(
					filter(([action, state]: [IStartAction, BaseState]) => {
						if (
							apiAction.settings.initialLoad &&
							state.loadActions[action.name] !== LoadingState.Loading &&
							(!action.forceLoad ||
								state.loadActions[action.name] !== LoadingState.Awaiting)
						) {
							if (action.onError) {
								action.onError(
									`'${action.type}' has already been loaded`,
									true
								);
							}
							return false;
						}
						return true;
					})
				);
				extraPipes.push(map(([action]) => action));
			}

			return actions$.pipe(
				ofType(apiAction.start.type),
				tap(action => (tempAction = action as IStartAction<TPayload>)),
				...(extraPipes as []),
				...(pipeList as []),
				switchMap(actionDataParam => {
					const actionData = actionDataParam as TPayload;
					start = new Date();
					return serviceCall(actionData).pipe(
						map(response => {
							this.logSuccess(tempAction, response, start);

							if (tempAction.onSuccess) {
								tempAction.onSuccess(response);
							}
							if (apiAction.settings.eager) {
								return apiAction.success(null as TResponse);
							}

							if (apiAction.settings.parseDates) {
								response = parseObject(response, x =>
									parseDate(x, apiAction.settings.utcDates)
								);
							}

							return apiAction.successWithData({
								data: response as TResponse,
								payload: tempAction.payload as TPayload,
							});
						}),
						catchError((data): Observable<IErrorAction<TPayload>> => {
							const correlationId: string =
								data?.headers?.get instanceof Function &&
								data?.headers?.get('x-correlation-id');
							const payload = data?.error ?? {};
							if (!payload.error) {
								payload.error = 'Something went wrong';
							}
							if (correlationId) {
								payload.correlationId = correlationId;
							}

							this.logError(tempAction, payload.error, start);
							if (tempAction.onError) {
								tempAction.onError(payload.error);
							}
							return of(
								apiAction.failWithData(payload) as IErrorAction<TPayload>
							);
						})
					);
				})
			);
		};
	}

	private logSuccess(action: IStartAction, result: any, start: Date) {
		if (this.#isProductionMode) {
			return;
		}

		const time = new Date().getTime() - start.getTime();
		const style = 'font-weight: bold';

		console.groupCollapsed(action.type);
		console.log('%cPayload: ', style, action.payload);
		console.log('%cReturn: ', style, result);
		console.log('%cTime: ', style, time < 1000 ? '< 1s' : `${time / 1000}s`);
		console.groupEnd();
	}

	private logError(action: IStartAction, error: string, start: Date) {
		if (this.#isProductionMode) {
			return;
		}

		const time = new Date().getTime() - start.getTime();
		const style = 'font-weight: bold; color: red';

		console.groupCollapsed(`%cERROR: ${action.type}`, 'color:red');
		console.log('%cPayload: ', style, action.payload);
		console.log('%cError: ', style, error);
		console.log('%cTime: ', style, time < 1000 ? '< 1s' : `${time / 1000}s`);
		console.groupEnd();
	}
}
