import { BaseState, LoadingState } from '@shared/models';
import { Action, ActionCreator, ActionReducer } from '@ngrx/store';
import {
	ApiAction,
	CustomAction,
	IErrorAction,
	IStartAction,
	ISuccessAction,
	NgrxActionCreator,
} from './action';
import { isResetStoresAction, resetAllStores } from './reset-stores';
import { cloneDeep } from 'lodash-es';
import { StoreScopes } from './store-scopes';

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiReducer<TState extends BaseState> {
	#apiReducers: ((state: TState | undefined, action: Action) => TState)[] = [];
	#customReducers: {
		[key: string]: ((state: TState | undefined, action: Action) => TState)[];
	} = {};

	constructor(private initialState: TState, private scope: StoreScopes) {}

	private getApiReducer<TPayload, TResponse>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		selector?: (
			/**
			 * The {@link IStartAction#payload} property is passed for start actions
			 * and the {@link ISuccessAction#data} property is passed for success
			 * actions.
			 */
			data: TResponse | TPayload,
			state: TState,
			payload?: TPayload
		) => Partial<TState>
	): (state: TState | undefined, action: Action) => TState {
		return (state: TState | undefined, action: Action): TState => {
			state ??= cloneDeep(this.initialState);

			const stateChange: Partial<BaseState> & Pick<BaseState, 'loadActions'> = {
				loadActions: { ...state.loadActions },
			};

			switch (action.type) {
				case apiAction.start.type: {
					const startAction = action as IStartAction<TPayload>;

					if (apiAction.settings.initialLoad) {
						if (state.loadActions[startAction.name] === LoadingState.Loading) {
							return {
								...state,
								loadActions: {
									...state.loadActions,
									[startAction.type]: LoadingState.Awaiting,
								},
							};
						}

						if (state.loadActions[startAction.name] === LoadingState.Awaiting) {
							return state;
						}

						if (
							!startAction.forceLoad &&
							state.loadActions[startAction.name] &&
							state.loadActions[startAction.name] !== LoadingState.Error
						) {
							return state;
						}

						stateChange.loaded = LoadingState.Loading;
						stateChange.loadActions[startAction.name] = LoadingState.Loading;
					}

					stateChange.updating = state.updating + 1;

					if (apiAction.settings.eager) {
						apiAction.saveBackup(state);

						if (selector) {
							state = {
								...state,
								...selector(
									startAction.payload || ({} as TPayload),
									state,
									startAction.payload
								),
							};
						} else {
							state = {
								...state,
								...(startAction.payload || {}),
							};
						}
					}

					return {
						...state,
						...stateChange,
					};
				}

				case apiAction.success.type: {
					const successAction = action as ISuccessAction<TResponse, TPayload>;
					const data = successAction.data;

					if (apiAction.settings.initialLoad) {
						stateChange.loaded = LoadingState.Loaded;
						stateChange.loadActions[successAction.name] = LoadingState.Loaded;
					}

					stateChange.updating = state.updating - 1;

					if (apiAction.settings.eager) {
						return { ...state, ...stateChange };
					}

					if (selector) {
						return {
							...state,
							...selector(data ?? (null as any), state, successAction.payload),
							...stateChange,
						};
					}

					return {
						...state,
						...data,
						...stateChange,
					};
				}

				case apiAction.fail.type: {
					const errorAction = action as IErrorAction<TPayload>;
					const backup = apiAction.settings.eager ? apiAction.loadBackup() : {};

					if (apiAction.settings.initialLoad) {
						stateChange.loaded = LoadingState.Error;
						stateChange.loadActions[errorAction.name] = LoadingState.Error;
					}

					stateChange.updating = state.updating - 1;

					return {
						...state,
						...backup,
						...stateChange,
					};
				}

				default:
					return state;
			}
		};
	}

	private getCustomReducer<TAction extends { payload?: any }>(
		selector?: (payload: any, state: TState) => Partial<TState>
	) {
		return (state: TState | undefined, action: Action & TAction) => {
			state ??= cloneDeep(this.initialState);

			if (selector) {
				return {
					...state,
					...selector(
						Object.prototype.hasOwnProperty.call(action, 'payload')
							? action.payload || null
							: state,
						state
					),
				};
			}
			return { ...state, ...(action.payload || {}) };
		};
	}

	private getSideEffect<TPayload, TResponse>(
		selector: (
			data: TResponse,
			state: TState,
			payload?: TPayload
		) => Partial<TState>
	) {
		return (state: TState, action: ISuccessAction<TResponse, TPayload>) => ({
			...state,
			...selector(action.data as any, state, action.payload),
		});
	}

	addApiReducer<TPayload, TResponse>(
		apiAction: ApiAction<TState, TPayload, TResponse>,
		selector?: (
			data: TResponse,
			state: TState,
			payload?: TPayload
		) => Partial<TState>
	): void {
		this.#apiReducers.push(this.getApiReducer(apiAction, selector as any));
	}

	addReducer<
		TPayload,
		TActionType extends string = string,
		TParameters extends any[] = any[],
		TReturnValue extends { payload: TPayload } = { payload: TPayload }
	>(
		action: NgrxActionCreator<TActionType, TParameters, TReturnValue>,
		selector?: (
			payload: TReturnValue['payload'],
			state: TState
		) => Partial<TState>
	): void;
	addReducer(
		action: ActionCreator<string, () => Action>,
		selector?: (state: TState) => Partial<TState>
	): void;
	addReducer<TIn, TAction, TPayload>(
		action: CustomAction<TIn, TAction> & ((x: any) => { payload?: TPayload }),
		selector?: (payload: TPayload, state: TState) => Partial<TState>
	): void;
	addReducer<TIn, TAction>(
		action: CustomAction<TIn, TAction>,
		selector?: (payload: TAction, state: TState) => Partial<TState>
	): void {
		if (!this.#customReducers[action.type]) {
			this.#customReducers[action.type] = [];
		}
		this.#customReducers[action.type].push(this.getCustomReducer(selector));
	}

	addSideEffect<TAlt, TPayload, TResponse>(
		apiAction: ApiAction<TAlt, TPayload, TResponse>,
		selector: (
			data: TResponse,
			state: TState,
			payload?: TPayload
		) => Partial<TState>
	) {
		if (!this.#customReducers[apiAction.success.type]) {
			this.#customReducers[apiAction.success.type] = [];
		}
		this.#customReducers[apiAction.success.type].push(
			this.getSideEffect(selector) as any
		);
	}

	getReducer(): ActionReducer<TState, Action> {
		return (state: TState | undefined, action: Action): TState => {
			if (
				action.type === resetAllStores.type ||
				(isResetStoresAction(action) && action.payload === this.scope)
			) {
				return cloneDeep(this.initialState);
			}

			state ??= cloneDeep(this.initialState);

			this.#apiReducers.forEach(r => (state = r(state, action)));

			if (
				Object.prototype.hasOwnProperty.call(this.#customReducers, action.type)
			) {
				this.#customReducers[action.type].forEach(
					r => (state = r(state, action))
				);
			}
			return state;
		};
	}
}
