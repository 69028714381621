import {
	NotificationChannelTypes,
	NotificationType,
} from '@consensus/connect/shared/notifications/domain';
import { FileTypes } from '@consensus/shared/shared/files/domain';

export enum UserNotificationViewedStatus {
	Unviewed = 'Unviewed',
	Viewed = 'Viewed',
}

export interface UfaNotificationsQueryParams {
	eventIds?: string[];
	notificationFilters?: UserNotificationViewedStatus[];
	notificationTypes?: NotificationType[];
	skip?: number;
	limit?: number;
}

export interface UserNotificationResponseDto {
	id: string;
	title: string;
	message: string | null;
	notificationType: NotificationType;
	publishDate: string;
	socialFeedPostId: string;
	urlSettings: {
		url: string | null;
		urlIsExternal: boolean;
		urlButtonText: string | null;
	};
	fileType: FileTypes;
	pinSettings: {
		pinned: boolean;
		dismissable: boolean;
	};
	viewed: boolean;
	eventId: string;
	eventSlug: string;
	eventName: string;
	eventShortName: string | null;
}

export interface UserNotificationQueryResponseDto {
	notifications: UserNotificationResponseDto[];
	totalCount: number;
}

export interface EventUserNotificationCount {
	eventId: string;
	eventSlug: string;
	eventName: string;
	count: number;
}

export interface ClientUserNotificationCount {
	eventCounts: EventUserNotificationCount[];
	totalCount: number;
	totalCountExcludingHiddenEvents: number;
}

export interface ManagerNotificationRequestDto {
	title: string;
	message: string;
	visibleToAll: boolean;
	groups: string[];
	notificationChannels: NotificationChannelTypes[] | null;
	expiresAt: string | null;
}

export enum UfaNotificationWebSocketMessages {
	NotificationDelete = 'NotificationDelete',
	Notification = 'Notification',
	NotificationRepublish = 'NotificationRepublish',
	NotificationUpdate = 'NotificationUpdate',
	NotificationCountUpdate = 'NotificationCountUpdate',
	NotificationCountRefetch = 'NotificationCountRefetch',
}
