import { inject, Injectable } from '@angular/core';
import { ScopeClient } from '@store/scope';
import { SlugQuery } from '@shared/models';
import { Event } from '@consensus/shared/shared/event/domain';
import { Client } from '@consensus/shared/shared/client/domain';

@Injectable({ providedIn: 'root' })
export class LoginService {
	readonly #scopeClient = inject(ScopeClient);

	client: Client;
	event: Event;
	eventClient: Client;

	async loadEvent(slug: string) {
		if (this.event?.slug == slug) {
			return { event: this.event, client: this.eventClient };
		}

		return await this.loadEventInternal({ slug });
	}

	async loadEventById(id: string) {
		if (this.event?.id == id) {
			return { event: this.event, client: this.eventClient };
		}

		return await this.loadEventInternal({ id });
	}

	private async loadEventInternal(query: SlugQuery) {
		const data = await this.#scopeClient.loadAnonEvent(query).toPromise();

		this.event = data.event;
		this.client = data.client;
		this.eventClient = data.client;
		return { event: this.event, client: this.client };
	}

	async loadClient(slug: string) {
		if (this.client?.slug == slug) {
			return this.client;
		}

		return await this.loadClientInternal({ slug });
	}

	async loadClientById(id: string) {
		if (this.client?.id == id) {
			return this.client;
		}

		return await this.loadClientInternal({ id });
	}

	private async loadClientInternal(query: SlugQuery) {
		this.client = await this.#scopeClient.loadAnonClient(query).toPromise();
		return this.client;
	}
}
