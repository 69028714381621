import { AgendaState, SessionSelectModel } from './agenda.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, keyBy } from 'lodash-es';
import {
	Agenda,
	SessionItemOption,
	SessionItemTypes,
} from '@consensus/connect/shared/agenda/domain';
import {
	sortByDateThenSortingKeyAsc,
	sortByDateTimeThenSortingKeyAsc,
} from '@lib/helpers';

export const agendaKey = 'agenda';

interface ManageState {
	[agendaKey]: AgendaState;
}

const selectManageState = createFeatureSelector<ManageState>('manage');

export const selectAgendaState = createSelector(
	selectManageState,
	state => state.agenda
);

export const selectAgendas = createSelector(selectAgendaState, s => s.agendas);
export const selectSessions = createSelector(
	selectAgendaState,
	s => s.sessions
);
export const selectSessionItems = createSelector(
	selectAgendaState,
	s => s.sessionItems
);

export const selectSessionSelect = createSelector(
	selectAgendaState,
	({ agendas, sessions }) => {
		const options: SessionSelectModel[] = [];

		const sorted = groupBy(
			[...sessions].sort(sortByDateTimeThenSortingKeyAsc(s => s.start)),
			s => s.agendaId ?? ''
		);
		[...agendas].sort(sortByDateThenSortingKeyAsc(a => a.date)).forEach(a =>
			Object.prototype.hasOwnProperty.call(sorted, a.id)
				? sorted[a.id]?.forEach(s =>
						options.push({
							id: s.id,
							name: s.name,
							agendaId: s.agendaId,
							agendaName: a?.name,
						})
				  )
				: null
		);

		return options;
	}
);

export const selectSessionItem = (id: string) =>
	createSelector(selectSessionItems, sessionItems =>
		sessionItems.find(s => s.id == id)
	);

export const selectAgendaData = createSelector(
	selectAgendaState,
	({ agendas, sessions, sessionItems }) => {
		const sesSort = groupBy(sessions, s => s.agendaId ?? '');
		const sesItemSort = groupBy(sessionItems, s => s.sessionId ?? '');

		const result: Agenda[] = [];

		agendas.forEach(a => {
			const newA = { ...a, live: 0, sessions: [] };

			(sesSort[a.id] ?? []).forEach(s => {
				const newS = { ...s, live: 0, sessionItems: sesItemSort[s.id] ?? [] };

				newS.sessionItems.forEach(item =>
					item.active || item.resultsActive ? newS.live++ : null
				);
				newA.live += newS.live;
				newA.sessions.push(newS);
			});

			newA.qaEnabled = newA.sessions.every(x => x.qaOpen == true);
			result.push(newA);
		});

		return result;
	}
);

export const selectSessionItemOptions = createSelector(selectAgendaState, s => {
	const agendas = keyBy(s.agendas, x => x.id);
	const sessions = keyBy(s.sessions, x => x.id);
	return s.sessionItems.map(item => {
		const session = sessions[item.sessionId];
		const agenda = agendas[session?.agendaId];

		return {
			id: item.id,
			name: item.name,
			type: item.type,
			agenda: agenda?.name,
			session: session?.name,
		} as SessionItemOption;
	});
});

export const selectBrainstormSessionItemOptions = createSelector(
	selectSessionItemOptions,
	x => x.filter(si => si.type === SessionItemTypes.Brainstorm)
);

export const selectColorVoteSessionItemOptions = createSelector(
	selectSessionItemOptions,
	x => x.filter(si => si.type === SessionItemTypes.ColorVote)
);

export const selectSurveySessionItemOptions = createSelector(
	selectSessionItemOptions,
	x => x.filter(si => si.type === SessionItemTypes.Survey)
);
