import { ComponentType } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PushNotifications } from '@capacitor/push-notifications';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';
import { NotificationType } from '@consensus/connect/shared/notifications/domain';
import { notificationLinkHandler } from '@consensus/connect/shared/notifications/util-link-handler';
import {
	UfaNotificationsFacadeStore,
	UserNotificationResponseDto,
} from '@consensus/connect/ufa/notifications/data-access';
import { firstValueFrom, map } from 'rxjs';
import { environmentToken } from '@environments/environment';

/** The data that we send along with push notifications */
interface ConnectPushNotificationData {
	eventId: string; // The ID of the event that the push notification is associated with
	connectNotificationId: string; // The ID of the notification in Connect
	notificationType: NotificationType;
	isInAppNotification: boolean;
	url: string | null; // Link included in the event
	socialFeedPostId: string; // ID of the referenced social media post (if any)
	openNotificationModalOnPushNotificationTap: boolean;
}

const getEventSlug = (
	apiServer: string,
	http: HttpClient,
	eventId: string
): Promise<string> =>
	firstValueFrom(
		http
			.get<{
				event: { slug: string; [key: string]: unknown };
				[key: string]: unknown;
			}>(`${apiServer}/api/event/${eventId}`)
			.pipe(map(result => result.event.slug))
	);

export const pushNotificationActionHandler = (): { init: () => void } => {
	const apiServer = inject(environmentToken).server;
	const http = inject(HttpClient);
	const router = inject(Router);
	const sentry = inject(sentryToken);
	const linkHandler = notificationLinkHandler();
	const dialog = inject(MatDialog);
	const notificationsFacade = inject(UfaNotificationsFacadeStore);

	const init = (): void => {
		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			async action => {
				const data: ConnectPushNotificationData = action.notification.data;
				const eventSlug = await getEventSlug(apiServer, http, data.eventId);

				sentry.captureMessage('pushNotification ActionPerformed', {
					tags: {
						pushNotification: true,
					},
					extra: {
						eventSlug: eventSlug,
						actionId: action.actionId,
						inputValue: action.inputValue,
						url: data.url,
						notification: JSON.stringify(action),
					},
				});

				const openNotificationModal = async (
					notification: UserNotificationResponseDto
				): Promise<void> => {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					const { UfaNotificationsNotificationModalComponent } = await import(
						'@consensus/connect/ufa/notifications/feature-modal'
					);

					const modal = dialog.open<
						typeof UfaNotificationsNotificationModalComponent,
						UserNotificationResponseDto,
						{ openLink: boolean }
					>(
						UfaNotificationsNotificationModalComponent as unknown as ComponentType<
							typeof UfaNotificationsNotificationModalComponent
						>,
						{
							data: notification,
							minWidth: 'min(500px, 96vw)',
							maxWidth: 'min(1000px, 96vw)',
							maxHeight: '90vh',
						}
					);
					modal.afterOpened().subscribe(() =>
						notificationsFacade.markNotificationAsViewed({
							notificationId: notification.id,
							eventId: notification.eventId,
						})
					);
					modal.afterClosed().subscribe(message => {
						if (message?.openLink) {
							linkHandler({
								notificationId: notification.id,
								notificationType: notification.notificationType,
								socialFeedPostId: notification.socialFeedPostId,
								url: notification.urlSettings.url,
								eventSlug,
							});
						}
					});
				};

				switch (data.notificationType) {
					case NotificationType.Link: {
						if (data.openNotificationModalOnPushNotificationTap) {
							notificationsFacade.getNotification({
								notificationId: data.connectNotificationId,
								markAsDismissed: true,
								onResponse: notification => {
									router.navigateByUrl(`/event/${eventSlug}`).then(() => {
										if (notification) {
											openNotificationModal(notification);
										}
									});
								},
							});
						} else {
							notificationsFacade.markNotificationAsViewed({
								notificationId: data.connectNotificationId,
								eventId: data.eventId,
							});
							notificationsFacade.dismissNotification(
								data.connectNotificationId
							);
							linkHandler({
								notificationId: data.connectNotificationId,
								notificationType: data.notificationType,
								socialFeedPostId: null,
								url: data.url,
								eventSlug,
							});
						}

						break;
					}
					case NotificationType.Text: {
						notificationsFacade.getNotification({
							notificationId: data.connectNotificationId,
							markAsDismissed: true,
							onResponse: notification => {
								router.navigateByUrl(`/event/${eventSlug}`).then(() => {
									if (notification) {
										openNotificationModal(notification);
									}
								});
							},
						});
						break;
					}
					case NotificationType.SocialFeedPost: {
						notificationsFacade.markNotificationAsViewed({
							notificationId: data.connectNotificationId,
							eventId: data.eventId,
						});
						router.navigateByUrl(
							`/event/${eventSlug}/socialfeed/${data.socialFeedPostId}`
						);
						break;
					}
					default: {
						data.notificationType satisfies never;
					}
				}
			}
		);
	};
	return { init };
};
