import { DOCUMENT } from '@angular/common';
import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormNode, FormNodeEvent, InputTypes } from '@lib/forms';
import { fromEvent, Subscription, asyncScheduler, throttleTime } from 'rxjs';

@Directive({
	standalone: true,
	selector: '[coTextEditable]',
})
export class TextEditableDirective implements OnInit, OnDestroy {
	readonly #controlContainer = inject(ControlContainer, {
		optional: true,
		skipSelf: true,
		host: true,
	});
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	readonly #document = inject(DOCUMENT);

	multiline = false;
	allowHtml = false;
	subs = new Subscription();

	@Input() controlName: string;
	@Input() control: FormNode<string>;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<void>();
	@Output() exit = new EventEmitter<void>();

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (event.code === 'Enter') {
			if (!this.multiline) {
				event.preventDefault();
				this.submit.emit();
				return;
			}

			if (event.ctrlKey || event.metaKey) {
				event.preventDefault();
				this.submit.emit();
				return;
			}

			if (!this.allowHtml) {
				event.preventDefault();
				this.#document.execCommand('insertHTML', false, '\n');
			}

			return;
		}

		if (event.code === 'Escape') {
			event.stopPropagation();
			this.exit.emit();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		if (!this.allowHtml) {
			event.preventDefault();
			let text = event.clipboardData.getData('text');

			text = text
				.replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/'/g, '&#39;')
				.replace(/"/g, '&#34;');

			if (!this.multiline) {
				text = text.replace('\n', '');
			}

			this.#document.execCommand('insertHTML', false, text);
		}
	}

	ngOnInit() {
		if (!this.control && !this.controlName) {
			return;
		}

		this.validateControl();
		if (!this.control) {
			return;
		}

		this.#element.nativeElement.contentEditable = 'true';

		if (this.control.type == InputTypes.LongText) {
			this.multiline = true;
			this.#element.nativeElement.style.whiteSpace = 'pre-wrap';
		} else if (this.control.type == InputTypes.HTML) {
			this.multiline = true;
			this.allowHtml = true;
		}

		this.subs.add(this.control.value$.subscribe(x => this.setValue(x)));

		this.subs.add(
			this.control.actions$.subscribe(x => {
				if (x === FormNodeEvent.Focus) {
					setTimeout(() => this.#element.nativeElement.focus());
				}
			})
		);

		this.subs.add(
			fromEvent(this.#element.nativeElement, 'input')
				.pipe(throttleTime(500, asyncScheduler, { trailing: true }))
				.subscribe(() =>
					this.control.setValue(
						this.allowHtml
							? this.#element.nativeElement.innerHTML
							: this.#element.nativeElement.innerText
					)
				)
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	private validateControl() {
		if (this.control instanceof FormNode) {
			return;
		}

		if (this.controlName) {
			const control = this.#controlContainer?.control?.get(this.controlName);
			if (control && control instanceof FormNode) {
				this.control = control;
				return;
			}
		}

		console.error('contenteditable was not given proper FormNode');
	}

	private setValue(text: string) {
		if (this.allowHtml) {
			if (text === this.#element.nativeElement.innerHTML) {
				return;
			}
			this.#element.nativeElement.innerHTML = text;
		} else {
			if (text === this.#element.nativeElement.textContent) {
				return;
			}
			this.#element.nativeElement.textContent = text;
		}
	}
}
