import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadProgressService {
	files: FileUploadProgress[] = [];

	constructor() {
		addEventListener(
			'beforeunload',
			e => {
				if (this.files.every(x => x.completed)) {
					return;
				}
				e.preventDefault();
				return (e.returnValue =
					'Are you sure you want to leave? All your ongoing uploads will be cancelled.');
			},
			{ capture: true }
		);
	}

	addFile(
		request: Promise<any>,
		progress: Observable<number>,
		name: string,
		size: number
	) {
		const file: FileUploadProgress = {
			name,
			size,
			progress: 0,
			processing: false,
			completed: false,
			failed: false,
		};

		this.files.push(file);

		const sub = progress.subscribe(p => {
			file.progress = p;
			if (p >= 80) {
				file.processing = true;
			}
		});

		request
			.catch(() => (file.failed = true))
			.finally(() => {
				file.completed = true;
				setTimeout(() => this.removeFile(file), 2000);
				sub.unsubscribe();
			});
	}

	removeFile(file: FileUploadProgress) {
		const i = this.files.findIndex(x => x === file);
		this.files.splice(i, 1);
	}
}

export interface FileUploadProgress {
	name: string;
	size: number;
	progress: number;
	processing: boolean;
	completed: boolean;
	failed: boolean;
}
