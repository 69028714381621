import { EventEmitter, inject, Injectable } from '@angular/core';
import { environmentToken } from '@environments/environment';
import { Router } from '@angular/router';
import {
	BehaviorSubject,
	takeUntil,
	fromEvent,
	map,
	filter,
	distinctUntilChanged,
	switchMap,
} from 'rxjs';
import { ErrorTypes } from '@shared/models';
import {
	HubConnection,
	HttpTransportType,
	HubConnectionBuilder,
	LogLevel,
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { selectUserGroups } from '@store/user';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';

interface ExcludeUserGroupsData<T> {
	data: T;
	groups: string[];
}

interface IgnoreMessageIdData<T> {
	ignoreId?: string;
	messageId: string;
	payload: T;
}

const isExcludeUserGroupsData = <T>(
	data: ExcludeUserGroupsData<T> | T
): data is ExcludeUserGroupsData<T> => {
	if (data == null) {
		return false;
	}
	if (typeof data !== 'object') {
		return false;
	}
	return (
		Object.keys(data).length === 2 &&
		Object.prototype.hasOwnProperty.call(data, 'data') &&
		Object.prototype.hasOwnProperty.call(data, 'groups')
	);
};

const isIgnoreMessageIdData = <T>(
	data: IgnoreMessageIdData<T> | T
): data is IgnoreMessageIdData<T> => {
	if (data == null) {
		return false;
	}
	if (typeof data !== 'object') {
		return false;
	}
	return Object.prototype.hasOwnProperty.call(data, 'messageId');
};

@Injectable({ providedIn: 'root' })
export class ConnectSharedDataAccessWebsocketService {
	readonly #sentry = inject(sentryToken);

	readonly #server = inject(environmentToken).server;
	readonly #store = inject(Store);
	readonly #router = inject(Router);
	#hubConnection?: HubConnection;

	connectionState$ = new BehaviorSubject<
		'NotConnected' | 'Connected' | 'Reconnecting'
	>('NotConnected');
	readonly afterConnected$ = this.connectionState$.pipe(
		distinctUntilChanged(),
		filter(state => state === 'Connected'),
		map(() => {
			return;
		})
	);
	readonly connectionClosed = new EventEmitter<void>();
	#starting?: Promise<void>;

	readonly #ignoredActions = new Set<string>();
	#userGroups?: string[];
	eventId?: string;

	constructor() {
		this.#store.select(selectUserGroups).subscribe(g => (this.#userGroups = g));
	}

	#checkIn() {
		this.#hubConnection?.invoke('UserCheckIn');
	}

	#checkInTimer?: ReturnType<typeof setTimeout> | null;
	private afterConnect() {
		if (this.#checkInTimer) {
			clearInterval(this.#checkInTimer);
			this.#checkInTimer = null;
		}
		this.#checkInTimer = setInterval(() => {
			this.#checkIn();
		}, 5 * 1000 * 60);
	}

	action<T>(
		methodName: string,
		opts?: {
			/**
			 * Skip the UserGroup and messageId socket extensions, defaults to `false`
			 */
			raw: boolean;
		}
	) {
		return this.afterConnected$.pipe(
			switchMap(() => {
				if (!this.#hubConnection) {
					throw new Error('ws-error: hub-connection not established');
				}

				const SKIP = {};

				return fromEvent(this.#hubConnection, methodName)
					.pipe(
						map(
							(
								data: ExcludeUserGroupsData<T> | IgnoreMessageIdData<T> | T
							): T | typeof SKIP => {
								if (opts?.raw) {
									return data as unknown as T;
								}

								if (isExcludeUserGroupsData(data)) {
									if (!this.#userGroups) {
										console.error('ws-error', 'this.userGroups is not set');
										this.#sentry.captureException(
											new Error('this.userGroup is not set')
										);
									}
									if (data.groups.some(x => this.#userGroups?.includes(x))) {
										return SKIP;
									}

									data = data.data;
								} else if (isIgnoreMessageIdData(data)) {
									const id = data.messageId;
									if (this.#ignoredActions.has(id)) {
										return SKIP;
									}
									if (data.ignoreId) {
										this.#ignoredActions.add(data.ignoreId);
									}
									data = data.payload;
								}

								return data;
							}
						),
						filter((x): x is T => x != SKIP)
					)
					.pipe(takeUntil(this.connectionClosed));
			})
		);
	}

	/**
	 * returns a promise when starting, and undefined if already started
	 */
	startConnection(
		eventId: string,
		isCms = false,
		accessTokenFactory: () => string
	) {
		if (this.#hubConnection) {
			return;
		}

		return new Promise<void>((resolve, reject) => {
			this.eventId = eventId;

			this.#hubConnection = new HubConnectionBuilder()
				.withUrl(
					`${this.#server}/api/connect?eventId=${eventId}&isEvent=${!isCms}`,
					{
						accessTokenFactory,
						skipNegotiation: true,
						transport: HttpTransportType.WebSockets,
					}
				)
				.withAutomaticReconnect([
					0, 5000, 5000, 5000, 10000, 10000, 10000, 10000,
				])
				.configureLogging(LogLevel.Warning)
				.withStatefulReconnect()
				.build();

			this.#hubConnection.on('[NOOP]', (data: IgnoreMessageIdData<never>) => {
				const id = data.messageId;
				if (this.#ignoredActions.has(id)) {
					return;
				}
				if (data.ignoreId) {
					this.#ignoredActions.add(data.ignoreId);
				}
			});

			this.#hubConnection.onclose(error => {
				if (error) {
					console.error('ws-error', 'onclose', error);
					this.#sentry.captureException(error);
				}
				if (this.#checkInTimer) {
					clearInterval(this.#checkInTimer);
					this.#checkInTimer = null;
				}
				this.eventId = undefined;

				if (this.connectionState$.value === 'Reconnecting') {
					this.onError();
				}

				this.connectionState$.next('NotConnected');
			});

			this.#hubConnection.onreconnecting(() => {
				console.warn('ws-warn', 'reconnecting');
				if (this.#checkInTimer) {
					clearInterval(this.#checkInTimer);
					this.#checkInTimer = null;
				}
				this.connectionState$.next('Reconnecting');
			});

			this.#hubConnection.onreconnected(() => {
				console.warn('ws-warn', 'reconnected');
				this.connectionState$.next('Connected');
				if (!isCms) {
					this.afterConnect();
					this.#checkIn();
				}
			});

			this.#starting = this.#hubConnection.start().then(
				() => {
					console.warn('ws-warn', 'connecting');
					this.connectionState$.next('Connected');
					if (!isCms) {
						this.afterConnect();
					}
				},
				error => {
					console.error('ws-error', 'Failed Initial Socket Connection', error);
					this.#sentry.captureException(error);
					this.connectionState$.next('NotConnected');
					if (error) {
						this.onError();
					}
				}
			);

			this.#starting
				.then(() => {
					this.#starting = undefined;
					resolve();
				})
				.catch(reject);
		});
	}

	async closeConnection() {
		if (this.#starting) {
			await this.#starting;
		}

		if (this.#hubConnection) {
			await this.#hubConnection.stop();
		}

		this.connectionState$.next('NotConnected');
		this.eventId = undefined;

		this.connectionClosed.emit();

		this.#hubConnection = undefined;
	}

	private onError() {
		this.#router.navigate(['/error'], {
			queryParams: { error: ErrorTypes.SocketError },
		});
	}
}
