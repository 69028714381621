import { inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class VideoOutroService implements OnDestroy {
	private static readonly storageKey = 'isNavigatingFromOtherDashboard';

	readonly #router = inject(Router);
	readonly #location = inject(Location);
	#subs = new Subscription();
	#navigatingBack = false;

	#setVideoToLastFrame$ = new BehaviorSubject(false);
	setVideoToLastFrame$ = this.#setVideoToLastFrame$.pipe(
		distinctUntilChanged()
	);

	outroVideoPlayed = false;
	currentDashboardId!: string;

	#previousDashboardId$ = new BehaviorSubject<string | undefined>(
		sessionStorage.getItem(VideoOutroService.storageKey) ?? undefined
	);

	get previousDashboardId() {
		return this.#previousDashboardId$.value;
	}

	get isNavigatingBackFromDashboard() {
		return !!this.#previousDashboardId$.value;
	}

	// A simple way to keep track of the previous and current url
	// See the use of the location.onUrlChange listener
	#history: [string | undefined, string | undefined] = [
		undefined,
		location.pathname,
	];

	constructor() {
		// Store last 2 location paths
		this.#subs.add(
			this.#location.onUrlChange(url => {
				const previousUrl = this.#history[1];

				if (url !== previousUrl) {
					this.#history.shift();
					this.#history.push(url);
				}
			})
		);

		// Listen for back navigation
		this.#subs.add(
			this.#location.subscribe(() => {
				const previousUrl = this.#history[0];
				if (!previousUrl) {
					return;
				}
				this.#onBackNav(previousUrl);
			})
		);

		// Listen for forward navigation
		this.#subs.add(
			this.#router.events.subscribe(event => {
				if (!(event instanceof NavigationEnd)) {
					return;
				}

				if (this.#navigatingBack) {
					this.#navigatingBack = false;
					return;
				}

				// Reset outro state
				this.#resetState();
			})
		);

		this.#subs.add(
			this.#previousDashboardId$.subscribe(val => {
				if (!val) {
					sessionStorage.removeItem(VideoOutroService.storageKey);
				} else {
					sessionStorage.setItem(VideoOutroService.storageKey, val);
				}
			})
		);
	}

	ngOnDestroy() {
		this.#subs.unsubscribe();
	}

	#onBackNav(previousUrl: string) {
		this.#navigatingBack = true;

		if (previousUrl.includes('/dashboard')) {
			this.#onBackNavFromDashboard();
		} else {
			this.#onBackNavFromNonDashboard();
		}
	}

	#onBackNavFromDashboard() {
		this.#previousDashboardId$.next(this.currentDashboardId);

		this.outroVideoPlayed = false;
		this.#setVideoToLastFrame$.next(false);
	}

	#onBackNavFromNonDashboard() {
		this.#previousDashboardId$.next(undefined);
		this.#setVideoToLastFrame$.next(true);
	}

	#resetState() {
		if (this.isNavigatingBackFromDashboard) {
			this.#previousDashboardId$.next(undefined);
			this.outroVideoPlayed = true;
		}

		this.#setVideoToLastFrame$.next(false);
	}

	outroVideoEnded() {
		if (this.isNavigatingBackFromDashboard) {
			this.#previousDashboardId$.next(undefined);

			this.outroVideoPlayed = true;
			this.#setVideoToLastFrame$.next(true);
		}
	}

	videoFailedToLoad(src: string, enablePlayOutroVideo: boolean) {
		if (enablePlayOutroVideo && src.includes('outro')) {
			console.warn('[OUTRO VIDEO] Failed to load the outro video file');
			this.#resetState();
		}
	}
}
