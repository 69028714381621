import { Directive, ElementRef, inject, Input, OnDestroy } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[coInnerVideo]',
})
export class InnerVideoDirective implements OnDestroy {
	readonly #elementRef: ElementRef<HTMLElement> = inject(ElementRef);

	#parent: HTMLElement = this.#elementRef.nativeElement;
	#child: HTMLVideoElement;
	@Input('coInnerVideo') set innerVideo(newChild: HTMLVideoElement) {
		if (this.#child === newChild) {
			return;
		}

		if (
			this.#child &&
			this.#child !== newChild &&
			this.#child.parentNode === this.#parent
		) {
			this.#parent.removeChild(this.#child);
		}

		if (newChild) {
			this.#parent.appendChild(newChild);
			this.#child = newChild;

			newChild.play();
		}
	}

	ngOnDestroy(): void {
		// This insanity is a fix for properly closing the WebMediaPlayer
		// Recommended fix according to HTML specification section 4.8.12.18:
		// https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
		// Break down of the issue and how to replicate, can be read on https://gitlab.com/consensusaps/connect/-/issues/2454
		if (this.#child) {
			this.#child.pause(); // can't hurt
			this.#child.src = null;
			this.#child.srcObject = null; // src is the only attribute HTML spec suggests removing, however HTMLMediaElement uses srcObject, so if we
			// don't remove this too, the issue won't get resolved. Additionally, spec also only suggests calling
			// .removeAttribute('src') (and in our case, removeAttribute('srcObject') too), however, if we don't set
			// this to null first, the issue doesn't get resolved. Complete insanity.
			this.#child.removeAttribute('src');
			this.#child.removeAttribute('srcObject');
			this.#child.load(); // Force the DOM to detect the changes we made to the HTML element
		}
	}
}
