/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT, NgIf } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	NgZone,
	OnDestroy,
	ViewChild,
	inject,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import { baseFroalaOptions } from '@lib/froala';
import FroalaEditor from 'froala-editor';
import {
	defaultTableColors,
	defaultTextColors,
	FroalaPreset,
	getFroalaButtons,
} from '@lib/froala';
import { ThemeService } from '@core/services';

@Component({
	standalone: true,
	selector: 'co-form-html-input',
	imports: [NgIf, MatTooltipModule],
	templateUrl: './html-input.component.html',
	styleUrls: ['./html-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class HtmlInputComponent
	extends InputBaseComponent<string>
	implements AfterViewInit, OnDestroy
{
	readonly #zone = inject(NgZone);
	readonly #themeService = inject(ThemeService);
	readonly #document = inject(DOCUMENT);

	@ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
	editor: FroalaEditor;

	@Input() singleLine = false;
	@Input() buttonLayout?: FroalaPreset;

	postprocessValue(value: string): any {
		if (!this.singleLine) {
			return value;
		}
		return value?.replace(/<br\/?>/, '');
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		const div = this.#document.createElement('div');
		this.wrapper.nativeElement.appendChild(div);
		/**
		 * We initialize Froala outside Zone.js
		 *
		 * This is to avoid countless needless change detection
		 * cycles as Froala listens to mousemove events
		 */
		this.#zone.runOutsideAngular(() => {
			this.editor = new FroalaEditor(div, {
				quickInsertEnabled: false,
				...baseFroalaOptions,
				...getFroalaButtons(
					this.buttonLayout ?? (this.singleLine ? 'simple' : 'normal')
				),
				/** All event handlers should be run in zone */
				events: {
					contentChanged: () => {
						this.#zone.run(() => (this.inputValue = this.editor.html.get()));
					},
					initialized: () => {
						this.#zone.run(() => this.editor.html.set(this.inputValue));
					},
				},
				multiLine: !this.singleLine,
				enter: this.singleLine ? FroalaEditor.ENTER_BR : FroalaEditor.ENTER_DIV,
				placeholderText: this.placeholder,
				imagePaste: false,
				imageUpload: false,
				colorsText: [
					...this.#themeService.getColorList(),
					...defaultTextColors,
				],
				colorsStep: 8,
				tableColors: [
					...this.#themeService.getColorList(),
					...defaultTableColors,
				],
				tableColorsStep: 8,
				pastePlain: this.singleLine,
				wordPasteKeepFormatting: !this.singleLine,
			});
		});
		this.valueSubs.add(
			this.inputValue$.subscribe(val => this.editor.html?.set(val))
		);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.editor?.destroy();
	}
}
