import RiveBuilder from '@rive-app/canvas-advanced';
import { RiveCanvas as Rive } from '@rive-app/canvas-advanced';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RIVE_VERSION, RIVE_WASM } from './tokens';
import { firstValueFrom, Observable, share } from 'rxjs';

const animationFrame = (rive: Rive) =>
	new Observable<number>(subscriber => {
		let start = 0;
		let first = true;
		const run = (time: number) => {
			const delta = time - start;
			start = time;
			if (first) {
				subscriber.next(16);
				first = false;
			} else {
				subscriber.next(delta);
			}
			// Because of bug in Chrome first value might be too big and cause issues
			if (subscriber.closed) {
				return;
			}
			rive.requestAnimationFrame(run);
		};
		rive.requestAnimationFrame(run);
	});

/**
 * RiveService used to load the Rive file. Exposes a single method to load
 * this file, using the rive.wasm.
 */
@Injectable()
export class CoRiveService {
	readonly #httpClient = inject(HttpClient);
	#riveVersion: string = inject(RIVE_VERSION, { optional: true }) ?? '2.4.3';
	#wasmPath: string =
		inject(RIVE_WASM, { optional: true }) ??
		`https://unpkg.com/@rive-app/canvas-advanced@${
			this.#riveVersion
		}/rive.wasm`;

	rive?: Rive;
	frame?: Observable<number>;

	async #getRive() {
		if (!this.rive) {
			const locateFile = () => this.#wasmPath;
			this.rive = await RiveBuilder({ locateFile });
			this.frame = animationFrame(this.rive).pipe(share());
		}
		return this.rive;
	}

	#getAsset(asset: string) {
		return firstValueFrom(
			this.#httpClient.get(asset, { responseType: 'arraybuffer' })
		);
	}

	/** Load a riv file */
	async load(file: string | File | Blob) {
		// Provide the file directly
		if (typeof file !== 'string') {
			const [rive, buffer] = await Promise.all([
				this.#getRive(),
				file.arrayBuffer(),
			]);
			return rive?.load(new Uint8Array(buffer));
		}
		let buffer: ArrayBuffer;
		const rive = await this.#getRive();
		// ISSUE #5772: We retry load if it fails as this hotfixes the CDN/CORS issue we are dealing with for Rive Drive files on production
		try {
			buffer = await this.#getAsset(file);
		} catch {
			buffer = await this.#getAsset(file).catch(err => {
				console.error(err);
				throw new Error('Could not load Rive file');
			});
		}
		if (!rive) {
			throw new Error('Could not load rive');
		}
		return rive.load(new Uint8Array(buffer));
	}
}
