import { Injectable } from '@angular/core';
import UAParser from 'ua-parser-js';

@Injectable({
	providedIn: 'root',
})
/**
 * For documentation and use of the UAParser, please refer to:
 * https://github.com/faisalman/ua-parser-js
 */
export class CoUserAgentService {
	userAgent = new UAParser();
	userDevice = this.userAgent.getDevice();

	getOS(): UAParser.IOS {
		return this.userAgent.getOS();
	}

	getDevice(): UAParser.IDevice {
		return this.userDevice;
	}

	getBrowser(): UAParser.IBrowser {
		return this.userAgent.getBrowser();
	}

	isMacOsOrIos(): boolean {
		const os = this.getOS()?.name;
		return os ? ['Mac OS', 'iOS'].includes(os) : false;
	}

	isIos(): boolean {
		return 'iOS' === this.getOS().name;
	}

	isAndroid(): boolean {
		return 'Android' === this.getOS().name;
	}

	isMobile(): boolean {
		return this.userDevice.type === 'mobile';
	}

	/** Note that some tablet devices identify as mobile, not tablet, including iPads */
	isTablet(): boolean {
		return this.userDevice.type === 'tablet';
	}

	isMobileOrTablet(): boolean {
		const userDeviceType = this.userDevice.type;
		return userDeviceType
			? ['mobile', 'tablet'].includes(userDeviceType)
			: false;
	}

	getOSVersion(): { major: number; minor: number; patch: number } | undefined {
		const version = this.getOS().version;
		return CoUserAgentService.parseVersion(version);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private static parseVersion(version: string | undefined):
		| {
				major: number;
				minor: number;
				patch: number;
		  }
		| undefined {
		const v = version?.match(/(\d+)(?:\.(\d+))?(?:\.(\d+))?/);

		if (!v) {
			return;
		}

		return {
			major: parseInt(v[1], 10),
			minor: parseInt(v[2], 10),
			patch: parseInt(v[3], 10),
		};
	}
}
